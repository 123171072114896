<template>
  <v-container>
    <v-btn outlined rounded color="#173c93" :to="getBackRoute()">
      Voltar
    </v-btn>

    <read-article :article="foundPrivacyNews" />
  </v-container>
</template>

<script>
import { externalBySlug } from "@/services/lgpd-service";

export default {
  name: "ReadPrivacyNews",
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.previousRoute = from;
    });
  },
  data() {
    return {
      foundPrivacyNews: {},
      previousRoute: { fullPath: "" },
    };
  },
  async created() {
    window.scrollTo({ top: 0, behavior: "smooth" });
    this.foundPrivacyNews = await externalBySlug(this.$route.params.slug);
  },
  methods: {
    getBackRoute() {
      return "/external/privacy-portal";
    },
  },
};
</script>
